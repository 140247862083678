<template>
    <div class="box">
        <div class="help-list">
            <el-collapse v-model="activeName"  accordion  v-loading="loading">
                <el-collapse-item v-for="(item, index) in helpList" :key="index" :title="item.class_name" :name="item.class_id" >
                    <div class="item-info">
                        <div class="item"
                             v-for="(helpItem, helpIndex) in item.child_list"
                             :key="helpIndex"
                              @click="detail(helpItem.id)">
                            <div class="item-title">{{ helpItem.title }}</div>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>

<script>
    import { helpList } from "@/api/cms/help";

    export default {
        name: "members_help",
        data() {
            return {
                activeName: [],
                helpList: [],
                yes: true,
                loading: true,
            };
        },
        created() {
            this.getHelpList()
        },
        methods: {
            detail(id) {
                this.$router.push({path:'/member/members_help_detail',query:{id: id}});
            },
            getHelpList() {
                helpList({
                    app_module: "member"
                }).then(res => {
                    if (res.code == 0 && res.data) {
                        this.helpList = res.data;
                        this.activeName.push(res.data[0].class_id)
                        var arr = [];
                        arr = res.data
                        for (let i=0; i<arr.length; i++) {
                            arr[i].child_list = arr[i].child_list;
                        }
                    }
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style lang="scss">
    .help-list .el-collapse{
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
    }
    .help-list .el-collapse div.el-collapse-item{
        padding: 0px 10px;
    }
    .help-list .el-collapse .el-collapse-item__header{
        font-size: 14px;
        font-weight: 700;
    }
    .help-list .el-collapse .el-collapse-item__content{
        padding-bottom: 0px
    }
</style>
<style lang="scss"  scoped>
    .box {
        width: 100%;
        position: relative;
        background-color: #FFFFFF;
    }

    .null-page {
        width: 100%;
        height: 730px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
    }

    .help-list {
        background: #ffffff;
        padding: 30px;
    }


    .item-info {
            width: 100%;
            padding: 10px;
            background-color: #ffffff;
        .item {
            border-bottom: 1px #EBEEF5 solid;
            padding: 10px 0;
            display: flex;
            justify-content: space-between;
            &:last-child {
                 border-bottom: none;
             }
            &:first-child {
                 padding-top: 0px;
             }
            .item-title {
                font-size: $ns-font-size-base;
                color: #333333;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                &:hover {
                     color: $base-color;
                 }
            }
        }
    }

</style>